import * as React from 'react'

import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

type Props = Omit<BoxProps, 'display'>

export const Flex = React.forwardRef<HTMLElement, Props>((props, ref) => <Box ref={ref} display="flex" {...props} />)

Flex.displayName = 'Flex'
