import * as React from 'react'

import { useId } from '@react-aria/utils'

import type { InfoTip } from './info-tip'
import { InfoTipContent } from './info-tip-content'
import type { Props as InfoTipInternalProps } from './info-tip-internal'
import { InfoTipInternal } from './info-tip-internal'

type InfoTipProps = React.ComponentProps<typeof InfoTip>

type InfoTipPropsObject = Omit<InfoTipProps, 'children'> & {
    body: InfoTipProps['children']
}

export type InfoTipProp = string | InfoTipPropsObject

export const getInfoTipByProps = ({
    props,
    isWrapperComponentDisabled = false,
    trigger,
}: {
    props: InfoTipProp
    isWrapperComponentDisabled?: boolean
    trigger?: InfoTipInternalProps['trigger']
}) => {
    if (typeof props === 'string') {
        return (
            <InfoTipInternal disabled={isWrapperComponentDisabled} trigger={trigger}>
                <InfoTipContent>{props}</InfoTipContent>
            </InfoTipInternal>
        )
    }

    const { label, body, disabled, ...rest } = props
    const infoTipProps = {
        ...rest,
        disabled: isWrapperComponentDisabled ? true : disabled,
    }

    const content = typeof body === 'string' ? <InfoTipContent>{body}</InfoTipContent> : body

    if (trigger) {
        return (
            <InfoTipInternal {...infoTipProps} trigger={trigger}>
                {content}
            </InfoTipInternal>
        )
    }

    return (
        <InfoTipInternal {...infoTipProps} label={label}>
            {content}
        </InfoTipInternal>
    )
}

export const useInfoTipWrapperId = (id: string | undefined, label: string | undefined) => {
    /**
     * The id and label props are optional. In this hook, we re-use the useId hook (SSR reasons). Since the hook cannot be called conditionally, it always needs to be called.
     *
     * - When there is no id or label provided, it will return undefined, because there is no reason to pass the id prop to the element (Checkbox, Switch, etc.).
     * - When the consumer provided the id prop, it will return the provided id, but the useId hook will be aware of this ID, again SSR. In this case, the id will be passed to the element, because the consumer provided it. Probably they provided the label as well, we don't care in this case, because if the label was provided or not, the id will be passed to the corresponding label element if it was provided (it's the component's responsibility, see the conditional rendering in Checkbox and Switch).
     * - When only the label was provided, then the useId hook will generate an ID that will be passed to the element and to the label via the htmlFor attribute. Conditional rendering again.
     *
     * For further details see the original discussion: https://github.com/pmedianetwork/adverity-frontend-monorepo/pull/3277#discussion_r1006730688
     */
    const elementId = useId(id)

    return id || label ? elementId : undefined
}
