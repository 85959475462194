import * as React from 'react'

import type { CSSProp, PatternTextProps, Variant } from '../../types'
import { Text } from '../text/text'

type Props = {
    /** sets the color of the text */
    variant?: Variant
} & Omit<PatternTextProps, 'css'> & { css?: CSSProp }

const textColorToVariantMap = {
    success: 'successActive',
    warning: 'warningHighlighted',
    danger: 'dangerActive',
} as const

const getColorProps = ({ variant }: { variant?: Variant }) => {
    if (variant) {
        return { color: textColorToVariantMap[variant] }
    }

    return { color: 'textSecondary' as const }
}

export const HelpText = React.forwardRef<HTMLElement, Props>((props, ref) => {
    const { variant, children, ...restOfProps } = props

    return (
        <Text ref={ref} textStyle="description" {...getColorProps({ variant })} {...restOfProps}>
            {children}
        </Text>
    )
})

HelpText.displayName = 'HelpText'
