import * as React from 'react'

type Slot = React.HTMLAttributes<HTMLElement> | Record<string, unknown>
type Slots = Record<string, Slot>

const SlotContext = React.createContext<Slots>({})

/**
 * Consumes slots props from parents.
 *
 * @example
 * const props =  useSlotProps(props, 'title')
 */
export function useSlotProps<T extends { slot?: string }>(props: T, defaultSlot: string): T & Slot {
    const slot = props.slot ?? defaultSlot
    const { [slot]: slotProps = {} } = React.useContext(SlotContext)

    return { ...props, ...slotProps }
}

/**
 * Provides slots for underlying components.
 *
 * @example
 * <SlotProvider slots={{ Title: { color: 'danger' } }}>
 *  {children}
 * </SlotProvider>
 */
export const SlotProvider = ({ slots, children }: { slots: Slots; children: React.ReactNode }) => {
    const parentSlots = React.useContext(SlotContext)
    const value = Object.fromEntries(
        [...Object.keys(parentSlots), ...Object.keys(slots)].map((current) => [
            current,
            {
                ...parentSlots[current],
                ...slots[current],
            },
        ]),
    )

    return <SlotContext.Provider value={value}>{children}</SlotContext.Provider>
}
