import * as React from 'react'

import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

type Props = BoxProps

export const BorderBox = React.forwardRef<HTMLElement, Props>((props, ref) => (
    <Box ref={ref} borderWidth="thin" borderColor="border" borderStyle="solid" {...props} />
))

BorderBox.displayName = 'BorderBox'
