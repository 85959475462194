import * as React from 'react'

import type { LimitedSize } from '../../types'

type GroupContextProps = {
    className: string
    disabled: boolean
    size?: LimitedSize
    isInGroup: boolean
}

// We should default GroupContext size to MEDIUM so it is inline with Group, when this issue is tackled https://github.com/pmedianetwork/design-system/issues/1998
export const GroupContext = React.createContext<GroupContextProps>({
    className: '',
    disabled: false,
    size: undefined,
    isInGroup: false,
})
