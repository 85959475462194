import * as React from 'react'
import { IconProps } from '../shared'
import { Icon } from '../icon'
export const IconClose = React.forwardRef<HTMLElement, IconProps>((props, ref) => (
    <Icon ref={ref} {...props}>
        <path
            fill="#111928"
            fillRule="evenodd"
            d="M7.757 6.343L12 10.585l4.244-4.242 1.414 1.414L13.414 12l4.243 4.244-1.414 1.414-4.244-4.243-4.242 4.243-1.414-1.414 4.242-4.244-4.242-4.242 1.414-1.414z"
            clipRule="evenodd"
        />
    </Icon>
))
IconClose.displayName = 'IconClose'
