import * as React from 'react'

import type { MiddlewareArguments } from '@floating-ui/react'
import { autoUpdate, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react'

import { usePopoverDefaultMiddleware } from './popover-middleware'
import type { PopoverContextProps, PopoverProps } from './types'
import { useInsideDialog } from '../dialog/dialog-internal'

export const PopoverContext = React.createContext<PopoverContextProps>(null)

export const Popover = (props: PopoverProps) => {
    const defaultMiddleware = usePopoverDefaultMiddleware()

    const {
        id,
        middleware = defaultMiddleware,
        placement = 'bottom',
        disablePortal = false,
        autoFocus = true,
        disableBackdrop = false,
        contain,
        restoreFocus,
        shouldCloseOnBlur = true,
        children,
        open: controlledOpen,
        onOpenChange: setControlledOpen,
        onAfterOpen,
    } = props

    const insideDialog = useInsideDialog()
    const [uncontrolledOpen, setUncontrolledOpen] = React.useState(false)

    const isOpen = controlledOpen ?? uncontrolledOpen
    const setIsOpen = setControlledOpen ?? setUncontrolledOpen

    const onAfterOpenMiddleware = {
        name: 'onAfterOpen',
        enabled: true,
        phase: 'afterWrite',
        fn: (middleWareProps: MiddlewareArguments) => {
            if (!middleWareProps.elements.floating.contains(document.activeElement) && autoFocus) {
                // Focus the popover once its opened
                middleWareProps.elements.floating.focus({ preventScroll: true })
            }

            if (onAfterOpen) {
                onAfterOpen()
            }

            return {}
        },
    }

    const floatingState = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement,
        middleware: [...middleware, onAfterOpenMiddleware],
        whileElementsMounted: autoUpdate,
    })

    const click = useClick(floatingState.context)
    const dismiss = useDismiss(floatingState.context, {
        outsidePress: (event) => {
            // Prevent click from firing on the backdrop of a dialog and closing it too if Popover is inside a Dialog
            if (!disableBackdrop || insideDialog) {
                event.preventDefault()
            }

            return true
        },
    })
    const interactionsState = useInteractions([click, dismiss])

    return (
        <PopoverContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                ...floatingState,
                ...interactionsState,
                id,
                isOpen,
                setIsOpen,
                disablePortal,
                autoFocus,
                disableBackdrop,
                contain,
                shouldCloseOnBlur,
                restoreFocus,
            }}
        >
            {children}
        </PopoverContext.Provider>
    )
}
