import * as React from 'react'

import type { Props as BoxProps } from './components/box/box'
import { Box } from './components/box/box'
import { useTheme } from './utils/use-theme'

/**
 * This component needs to override the same styles that are configured in
 * `CSSReset` globally because nested `ThemeProvider` usages are meant to be
 * inheriting values from a different context
 */
export const CSSScope = ({ children, css, ...props }: BoxProps) => {
    const theme = useTheme()

    return (
        <Box
            css={[
                {
                    fontFamily: theme.fonts.body,
                    color: theme.colors.text,
                    '& label': {
                        fontWeight: theme.fontWeights.regular,
                    },
                },
                css,
            ]}
            {...props}
        >
            {children}
        </Box>
    )
}
