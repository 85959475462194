import * as React from 'react'

import { useFieldContext } from './context'
import type { CommonProps } from './field-internal'
import type { Orientation } from '../../types'
import { getFocusRing, useFocusStyle } from '../../utils/focus'
import { groupItemCSSClassName } from '../checkbox-radio-group/common/constants'
import { Flex } from '../flex/flex'
import { DEFAULT_WIDTH } from '../form/constants'

type Props = Pick<CommonProps, 'tooltip'> &
    Required<Pick<CommonProps, 'orientation'>> & {
        children: React.ReactNode
        isWithoutLabel: boolean
    }

export const FieldLayout = React.forwardRef<HTMLDivElement, Props>(
    ({ children, isWithoutLabel, orientation, tooltip, ...props }, ref) => {
        const { fullWidth } = useFieldContext()
        const focusStyles = useFocusStyle()

        return (
            /**
             * We need to spread the props because when the tooltip is present some additional props will be passed
             * to it during the cloning phase.
             */
            <Flex
                ref={ref}
                width={fullWidth ? 'auto' : DEFAULT_WIDTH}
                tabIndex={tooltip ? 0 : undefined}
                css={[getBaseStyles(orientation, isWithoutLabel), tooltip ? getFocusRing(focusStyles) : undefined]}
                {...props}
            >
                {children}
            </Flex>
        )
    },
)

FieldLayout.displayName = 'FieldLayout'

const gridTemplateAreas = {
    vertical: {
        'with-label': `
            "label"
            "helpText"
            "content"
            "errorMessage"
        `,
        'without-label': `
            "helpText"
            "content"
            "errorMessage"
        `,
    },
    horizontal: {
        'with-label': `
            "label content"
            "empty helpText"
            "empty errorMessage"
        `,
        'without-label': `
            "content"
            "helpText"
            "errorMessage"
        `,
    },
    'vertical-inline': {
        'with-label': `
            "label"
            "content"
            "helpText"
            "errorMessage"
        `,
        'without-label': `
            "content"
            "helpText"
            "errorMessage"
        `,
    },
}

const gridTemplateColsRows = {
    horizontal: {
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: '1fr auto',
    },
    vertical: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto auto',
    },
    'vertical-inline': {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto auto',
    },
}

function getBaseStyles(orientation: Orientation, isWithoutLabel: boolean) {
    const isVerticallyOriented = orientation === 'vertical'

    return {
        display: 'grid',
        gridColumnGap: isWithoutLabel ? 'xx-small' : 'x-small',
        gridRowGap: '0',
        alignItems: 'center',
        ...gridTemplateColsRows[orientation],
        gridTemplateAreas: gridTemplateAreas[orientation][isWithoutLabel ? 'without-label' : 'with-label'],

        /**
         * The `CheckboxGroup` and `RadioGroup` items add a small spacing below the label even if the description
         * is not provided, even for the last child in the group. It causes a small visual issue with the new
         * field layout because when the field is horizontally oriented and the group is also horizontally
         * oriented the small spacing below the group items makes the group not perfectly aligned to the center.
         * Adding a small negative margin to the group element eliminates this visual problem.
         *
         * TODO: After Firefox starts supporting the :has CSS selector we can use the following:
         * > div[role="group"]:has(> .checkbox-radiogroup-group-item)
         */
        [`& > .field-input-wrapper > div[role="group"] .${groupItemCSSClassName}, & > .field-input-wrapper' > div[role="radiogroup"] .${groupItemCSSClassName}`]:
            {
                marginBottom: isVerticallyOriented ? undefined : 'negative-xx-small',
            },
        '& > span': {
            width: '100%',
        },
    }
}
