import * as React from 'react'

import { LocaleProvider } from '@adverity/design-system'

type Props = React.ComponentProps<typeof LocaleProvider>

export const DatatapLocaleProvider = ({ children, ...props }: Props) => (
    <LocaleProvider locale={navigator.language} {...props}>
        {children}
    </LocaleProvider>
)
