import * as React from 'react'

import { Global, css } from '@emotion/core'

import { isServer } from './utils/is-server'
import { isVisualRegressionEnv } from './utils/is-visual-regression-env'
import { useTheme } from './utils/use-theme'

/**
 * We're using a shared global constant here, to support cases where apps
 * can render multiple unrelated react roots
 */
type GlobalState = {
    injected?: string
    queue: Array<{ id: string; callback: VoidFunction }>
    mount(props: { id: string; callback: VoidFunction }): void
    unmount(id: string): void
}

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        _DS_GLOBAL_CSS_RESET_STATE?: GlobalState
    }
}

const globalState: GlobalState | undefined =
    // eslint-disable-next-line no-underscore-dangle
    !isServer() && window._DS_GLOBAL_CSS_RESET_STATE
        ? // eslint-disable-next-line no-underscore-dangle
          window._DS_GLOBAL_CSS_RESET_STATE
        : {
              queue: [],
              mount({ id, callback }) {
                  if (this.injected) {
                      this.queue = [...this.queue, { id, callback }]
                  } else {
                      this.injected = id

                      callback()
                  }
              },
              unmount(id) {
                  if (this.injected === id) {
                      const [next, ...queue] = this.queue

                      this.queue = queue

                      if (next) {
                          this.injected = next.id
                          next.callback()
                      }
                  } else {
                      this.queue = this.queue.filter((entry) => entry.id !== id)
                  }
              },
          }

if (!isServer()) {
    // eslint-disable-next-line no-underscore-dangle, functional/immutable-data
    window._DS_GLOBAL_CSS_RESET_STATE = globalState
}

type Props = {
    isTopLevel: boolean
}

export const CSSReset = ({ isTopLevel }: Props) => {
    const theme = useTheme()
    const id = React.useId()
    const [visible, setVisible] = React.useState(false)

    React.useEffect(() => {
        if (!isTopLevel) {
            return undefined
        }

        globalState.mount({ id, callback: () => setVisible(true) })

        return () => {
            globalState.unmount(id)
        }
    }, [isTopLevel, id, setVisible])

    if (!isTopLevel || !visible) {
        return null
    }

    const styles = css({
        body: {
            fontFamily: theme.fonts.body,
            color: theme.colors.text,
            margin: 0,
        },
        ...(isVisualRegressionEnv && {
            '*, ::after, ::before': {
                animation: 'none !important',
            },
        }),

        label: {
            fontWeight: theme.fontWeights.regular,
        },
    })

    return <Global styles={styles} />
}
