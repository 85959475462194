import * as React from 'react'

import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

type Props = Pick<BoxProps, 'children' | 'id'>

const stylesTip = {
    maxWidth: '320px',
    padding: 'x-small',
    backgroundColor: 'backgroundInverted',
    borderRadius: 'medium',
} as const

export const InfoTipBox = ({ children, id }: Props) => (
    <Box id={id} css={stylesTip}>
        {children}
    </Box>
)
