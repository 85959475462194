import * as React from 'react'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export function usePreventScroll({
    targetRef,
    enabled,
}: {
    targetRef: React.RefObject<HTMLElement>
    enabled: boolean
}) {
    React.useEffect(() => {
        const element = targetRef.current

        if (!element || !enabled) {
            return undefined
        }

        disableBodyScroll(element, { reserveScrollBarGap: true })

        return () => enableBodyScroll(element)
    }, [targetRef, enabled])
}
