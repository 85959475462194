import * as React from 'react'
import { IconProps } from '../shared'
import { Icon } from '../icon'
export const IconCloseLarge = React.forwardRef<HTMLElement, IconProps>((props, ref) => (
    <Icon ref={ref} {...props}>
        <path
            fill="#111928"
            fillRule="evenodd"
            d="M6 4l6 6 6-6 2 2-6 6 6 6-2 2-6-6-6 6-2-2 6-6-6-6 2-2z"
            clipRule="evenodd"
        />
    </Icon>
))
IconCloseLarge.displayName = 'IconCloseLarge'
