import * as React from 'react'

import { StoryContext } from './story-context'

export const useShouldActionActivatedInStory = () => {
    const storyContext = React.useContext(StoryContext)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return storyContext.parameters?.isActionActivated
}
