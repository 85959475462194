import * as React from 'react'

import { type LabelAria } from '@react-aria/label'

import type { Variant } from '../../types'
import { SlotProvider, useSlotProps } from '../../utils/components/slots'

const slotName = 'field'

export type FieldContext = {
    variant?: Variant
    disabled?: boolean
    readOnly?: boolean
    required?: boolean
    fullWidth?: boolean
    fieldProps?: LabelAria['fieldProps'] & {
        'aria-invalid'?: boolean
        'aria-errormessage'?: string
    }
}

export const useFieldContext = (): FieldContext => {
    const { slot: slotIgnored, ...context } = useSlotProps<FieldContext & { slot?: typeof slotName }>({}, slotName)

    return context
}

export const FieldContextProvider = ({
    children,
    disabled,
    readOnly,
    required,
    variant,
    fullWidth,
    fieldProps,
}: React.PropsWithChildren<FieldContext>) => {
    const slots = React.useMemo(
        () => ({
            [slotName]: {
                disabled,
                readOnly,
                required,
                variant,
                fullWidth,
                fieldProps,
            },
        }),
        [disabled, readOnly, required, variant, fullWidth, fieldProps],
    )

    return <SlotProvider slots={slots}>{children}</SlotProvider>
}

export const FieldSetContext = React.createContext(false)
