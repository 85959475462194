import type * as React from 'react'

import type { IconButtonProps } from './types'
import * as sizeMapping from '../../utils/size-mapping'
import type { ButtonBase } from '../button/button-base'

export const getIconButtonProps = ({
    icon,
    rounded,
    size = 'medium',
    isLoading,
    css,
    ...props
}: Omit<IconButtonProps, 'label'>): React.ComponentProps<typeof ButtonBase> => ({
    borderRadius: rounded ? 'round' : 'medium',
    paddingX: 'none',
    height: sizeMapping.height[size],
    width: sizeMapping.height[size],
    minWidth: 'initial',
    children: isLoading ? null : icon,
    size,
    css: [isLoading ? { gap: 0 } : {}, css],
    isLoading,
    isButtonWithLabel: false,
    ...props,
})
