import * as React from 'react'

import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

type Props = BoxProps

export const backdropZIndex = 6

export const Backdrop = React.forwardRef<HTMLElement, Props>((props, ref) => (
    <Box
        data-backdrop
        ref={ref}
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        zIndex={backdropZIndex}
        backgroundColor="shadow"
        {...props}
    />
))

Backdrop.displayName = 'Backdrop'
