import * as React from 'react'

import { FocusScope } from '@react-aria/focus'
import { useOverlay } from '@react-aria/overlays'

import { SlotProvider } from '../../utils/components/slots'
import { useComposeRefs } from '../../utils/use-compose-refs'
import { useIsomorphicLayoutEffect } from '../../utils/use-isomorphic-layout-effect'
import { usePreventScroll } from '../../utils/use-prevent-scroll'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

const InsideDialogContext = React.createContext(false)

export const useInsideDialog = () => React.useContext(InsideDialogContext)

export type Props = {
    children: React.ReactNode
    onClose?: (event: CustomEvent<unknown>) => void
    isDismissable?: boolean
    contain?: boolean
    autoFocus?: boolean
    restoreFocus?: boolean
    preventScroll?: boolean
    shouldCloseOnBlur?: boolean
    isKeyboardDismissDisabled?: boolean
    isOutsideDismissable?: boolean
    role?: 'dialog' | 'alertdialog'
} & Omit<BoxProps, 'role'>

export const DialogInternal = React.forwardRef<HTMLElement, Props>(
    (
        {
            children,
            onClose,
            isDismissable = true,
            contain,
            autoFocus,
            restoreFocus,
            preventScroll,
            shouldCloseOnBlur,
            isOutsideDismissable = true,
            role = 'dialog',
            isKeyboardDismissDisabled = false,
            onKeyDown,
            ...props
        },
        ref,
    ) => {
        const dialogRef = React.useRef<HTMLElement>(null)
        const [show, setShow] = React.useState(false)
        const handleOnClick = () => {
            const event = new CustomEvent('overlayMouseClick', {
                cancelable: true,
            })

            onClose?.(event)
        }

        // We need to explicitely destructure css and ignore it,
        // as it is a global property being injected by emotion globally.
        // The fact that overlayProps from useOverlay has a very broad type
        // HTMLAttributes<HTMLElement> results into a return type that
        // contains the global injected css prop
        const {
            overlayProps: { color: colorIgnored, ...overlayProps },
        } = useOverlay(
            {
                isOpen: true,
                onClose: handleOnClick,
                isDismissable,
                shouldCloseOnBlur,
                isKeyboardDismissDisabled,
                shouldCloseOnInteractOutside(target: HTMLElement | null) {
                    // For Drawers we want to close them only when clicking on the backdrop.
                    // This is due to drawers potentially having multiple interactive elements or evoking a toasts which would be considered as outside interaction.
                    if (!isOutsideDismissable) {
                        if (target?.getAttribute('data-backdrop')) {
                            return true
                        }

                        return false
                    }

                    // Fixes the case where closing Popover within Dialog closed both of them
                    // due to `relatedTarget` being `null` in case of blur event
                    return target !== null
                },
            },
            dialogRef,
        )

        const id = React.useId()
        const titleId = props['aria-label'] ? undefined : id

        usePreventScroll({ targetRef: dialogRef, enabled: !!preventScroll })

        // Render children on the second render of this component to prevent useLayoutEffect race-conditions
        useIsomorphicLayoutEffect(() => setShow(true), [setShow])

        // We need to initially set autoFocus to false, so that hook inside of the FocusScope sees the
        // changes in the children.

        return (
            <InsideDialogContext.Provider value>
                <FocusScope contain={contain} autoFocus={show && autoFocus} restoreFocus={restoreFocus}>
                    <Box
                        ref={useComposeRefs(dialogRef, ref)}
                        position="fixed"
                        backgroundColor="background"
                        borderWidth="none"
                        borderRadius="large"
                        boxShadow="medium"
                        color="text"
                        {...props}
                        {...overlayProps}
                        onKeyDown={(event) => {
                            overlayProps.onKeyDown?.(event)

                            onKeyDown?.(event)
                        }}
                        role={role}
                        tabIndex={-1}
                        aria-labelledby={props['aria-labelledby'] ?? titleId}
                    >
                        {show && (
                            <SlotProvider
                                slots={{
                                    header: {
                                        id: titleId,
                                    },
                                }}
                            >
                                {children}
                            </SlotProvider>
                        )}
                    </Box>
                </FocusScope>
            </InsideDialogContext.Provider>
        )
    },
)

DialogInternal.displayName = 'DialogInternal'
