import * as React from 'react'

import type { IconStaticProps } from '../components/icons/shared'

export const isComponentInstance = <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TElement extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>,
>(
    node: unknown,
    expected: TElement,
): node is React.ReactElement<React.ComponentProps<TElement>> => React.isValidElement(node) && node.type === expected

export const isIcon = (element: React.ReactElement): element is React.ReactElement<IconStaticProps> =>
    (element.type as React.FC).displayName?.startsWith('Icon') ?? false
