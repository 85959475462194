/* eslint id-length: ["error", { "exceptions": ["x", "y"] }] */

import * as React from 'react'

import { useMergeRefs } from '@floating-ui/react'
import type { Strategy, UseFloatingReturn, useInteractions } from '@floating-ui/react'
import { keyframes } from 'emotion'

import { tooltipInternalDelay } from './constants'
import { Box } from '../box/box'
import { Portal } from '../portal/portal'

type Props = {
    children: React.ReactNode
    id: string
    isOpen: boolean
    hiding: boolean
    strategy: Strategy
    getFloatingProps: ReturnType<typeof useInteractions>['getFloatingProps']
    setFloating: UseFloatingReturn['refs']['setFloating']
    x: UseFloatingReturn['x']
    y: UseFloatingReturn['y']
}

const animationIn = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1 }
`

export const TooltipInternalContent = React.forwardRef<HTMLDivElement, Props>(
    ({ children, isOpen, hiding, strategy, x, y, getFloatingProps, setFloating, id }, propsRef) => {
        const ref = useMergeRefs([setFloating, propsRef])

        if (!isOpen) {
            return null
        }

        return (
            <Portal>
                <Box
                    backgroundColor="backgroundInverted"
                    color="textInverted"
                    padding="x-small"
                    borderRadius="medium"
                    boxShadow="medium"
                    maxWidth={400}
                    css={{
                        fontSize: 'x-small',
                        lineHeight: 'xx-small',
                        wordBreak: 'break-word',
                        zIndex: 9,
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        animation: animationIn,
                        animationDelay: `${tooltipInternalDelay}ms`,
                        animationDuration: '1ms',
                        animationFillMode: 'backwards',
                        animationIterationCount: 1,
                        animationPlayState: hiding ? 'paused' : 'running',
                    }}
                    ref={ref}
                    aria-hidden={!isOpen}
                    {...getFloatingProps({ id })}
                >
                    {children}
                </Box>
            </Portal>
        )
    },
)

TooltipInternalContent.displayName = 'TooltipInternalContent'
