import * as React from 'react'

import type { Props as HeadingProps } from '../heading/heading'
import { Heading } from '../heading/heading'
import { Text } from '../text/text'

type Props = {
    children: React.ReactNode
} & Omit<HeadingProps, 'level'>

export const DialogTitle = React.forwardRef<HTMLElement, Props>(({ children, ...props }, ref) => (
    <Heading ref={ref} level={3} as="h2" css={{ minWidth: 0, whiteSpace: 'initial', display: 'flex' }} {...props}>
        <Text maxWidth="100%" textStyle="inherit" color="inherit" truncateLines={1}>
            {children}
        </Text>
    </Heading>
))

DialogTitle.displayName = 'DialogTitle'
