import * as React from 'react'

import { DialogProvider, LocaleProvider, ThemeProvider, ToastProvider, defaultTheme } from '@adverity/design-system'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { flushSync } from 'react-dom'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { retryWhenServerError } from '../api'
import { DatatapLocaleProvider } from '../features/DatatapLocaleProvider'

type RenderProps = {
    component: React.ReactNode
    container: HTMLElement
    wrapperProps?: React.ComponentProps<typeof ThemeProvider>['wrapperProps']
    renderToastProvider?: boolean
}

type PropsComparator<TComponent extends React.ComponentType> = (
    prevProps: Readonly<React.ComponentProps<TComponent>>,
    nextProps: Readonly<React.ComponentProps<TComponent>>,
) => boolean

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: retryWhenServerError,
        },
    },
})

export const render = ({ component, container, wrapperProps, renderToastProvider = false }: RenderProps) => {
    createRoot(container).render(
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ThemeProvider theme={defaultTheme} wrapperProps={wrapperProps}>
                <DatatapLocaleProvider weekStartsOn={1}>
                    {renderToastProvider && <ToastProvider />}
                    <DialogProvider>{component}</DialogProvider>
                </DatatapLocaleProvider>
            </ThemeProvider>
        </QueryClientProvider>,
    )
}

const RenderToStringProviders = ({ children }: { children: React.ReactNode }) => (
    <QueryClientProvider client={queryClient}>
        <ThemeProvider>
            <LocaleProvider locale={navigator.language}>
                <DialogProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="*" element={children} />
                        </Routes>
                    </BrowserRouter>
                </DialogProvider>
            </LocaleProvider>
        </ThemeProvider>
    </QueryClientProvider>
)

export const renderToString = (tree: React.ReactNode) => {
    const div = document.createElement('div')
    const root = createRoot(div)

    flushSync(() => {
        root.render(<RenderToStringProviders>{tree}</RenderToStringProviders>)
    })

    const html = div.innerHTML

    root.unmount()

    return html
}

export const renderToStringAsync = (tree: React.ReactNode) => {
    const div = document.createElement('div')
    const root = createRoot(div)

    return new Promise<string>((resolve) => {
        queueMicrotask(() => {
            flushSync(() => {
                root.render(<RenderToStringProviders>{tree}</RenderToStringProviders>)
            })

            resolve(div.innerHTML)
            root.unmount()
        })
    })
}

// The solution is based on the github comment by @pie6k
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-699521381
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const componentMemo = <TComponent extends React.ComponentType<any>>(
    Component: TComponent,
    propsComparator?: PropsComparator<TComponent>,
) => React.memo(Component, propsComparator) as unknown as TComponent
