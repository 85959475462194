import * as React from 'react'

import { Backdrop } from '../backdrop/backdrop'
import type { Props as BoxProps } from '../box/box'

type Props = BoxProps

export const DialogBackdrop = React.forwardRef<HTMLElement, Props>((props, ref) => <Backdrop ref={ref} {...props} />)

DialogBackdrop.displayName = 'DialogBackdrop'
