import * as React from 'react'

import type { CommonProps } from './field-internal'
import { FieldInternal } from './field-internal'

type Props = CommonProps & {
    label: string
}

export const Field = ({ children, ...props }: Props) => <FieldInternal {...props}>{children}</FieldInternal>

Field.displayName = 'Field'
