import * as React from 'react'

type RegisterProps = {
    id: string
    setVisible(visible: boolean): void
}

type InternalRef = {
    lastOpened: string | null
    callbacks: Record<string, (visible: boolean) => void>
}

type Context = {
    register(props: RegisterProps): VoidFunction
    toggle(id: string): void
}

const TooltipInternalContext = React.createContext<Context>({
    register: () => () => {},
    toggle: () => {},
})

export const TooltipInternalProvider = ({ children }: { children: React.ReactNode }) => {
    const ref = React.useRef<InternalRef>({
        lastOpened: null,
        callbacks: {},
    })

    const register = React.useCallback(({ id, setVisible }: RegisterProps) => {
        if (!ref.current.callbacks[id]) {
            ref.current.callbacks[id] = setVisible
        }

        return () => {
            const { [id]: ignored, ...rest } = ref.current.callbacks

            ref.current.callbacks = rest
        }
    }, [])

    const toggle = React.useCallback((id: string) => {
        const { callbacks, lastOpened } = ref.current

        if (callbacks[id] && lastOpened !== id) {
            if (lastOpened) {
                callbacks[lastOpened]?.(false)
            }

            ref.current.lastOpened = id
        }
    }, [])

    const providerValue = React.useMemo(
        () => ({
            register,
            toggle,
        }),
        [register, toggle],
    )

    return <TooltipInternalContext.Provider value={providerValue}>{children}</TooltipInternalContext.Provider>
}

export const useTooltipInternalProvider = ({ id, setVisible }: RegisterProps) => {
    const { register, toggle } = React.useContext(TooltipInternalContext)

    React.useEffect(() => register({ id, setVisible }), [id, setVisible, register])

    return React.useCallback(() => toggle(id), [toggle, id])
}
