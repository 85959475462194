import * as React from 'react'

import { arrow, flip, limitShift, offset, shift } from '@floating-ui/react'
import { useId } from '@react-aria/utils'

import { contentFallbackPlacements } from './constants/content-fallback-placements'
import { InfoTipArrow } from './info-tip-arrow'
import { InfoTipBox } from './info-tip-box'
import type { Props as InfoTipTriggerProps } from './info-tip-trigger'
import { InfoTipTrigger } from './info-tip-trigger'
import { px } from '../../utils/css-unit'
import { useOffset } from '../../utils/use-modifiers'
import { backdropZIndex } from '../backdrop/backdrop'
import { Popover } from '../popover/popover'
import { PopoverContent } from '../popover/popover-content'
import { PopoverTrigger } from '../popover/popover-trigger'

type CommonProps = {
    autoFocus?: boolean
    children: React.ReactNode
    disabled?: boolean
    disablePortal?: boolean
    id?: string
    isInitiallyOpen?: boolean
    label?: string
}

type TriggerProps = CommonProps & {
    label?: never
    trigger: (props: InfoTipTriggerProps) => React.ReactElement
}

type LabelProps = CommonProps & {
    trigger?: never
}

export type Props = TriggerProps | LabelProps

export const InfoTipInternal = ({
    autoFocus = true,
    children,
    disabled,
    disablePortal = false,
    id,
    isInitiallyOpen = false,
    trigger,
    label,
}: Props) => {
    const componentId = useId(id)
    const arrowRef = React.useRef<HTMLElement>(null)

    const [isOpen, setIsOpen] = React.useState(!disabled && isInitiallyOpen)

    const handleTriggerOnClick = () => setIsOpen(!isOpen)

    const [isTooltipVisible, setIsTooltipVisible] = React.useState(isOpen && !isInitiallyOpen)

    const onMouseLeave = () => {
        setIsTooltipVisible(false)
    }
    const onMouseEnter = () => {
        if (!isOpen) {
            setIsTooltipVisible(true)
        }
    }

    const infoTipTriggerProps = {
        ariaExpanded: isOpen,
        ariaControls: componentId,
        disabled,
        onClick: () => {
            handleTriggerOnClick()
            onMouseLeave()
        },
        isTooltipVisible,
        onMouseEnter,
        onMouseLeave,
        onFocus: onMouseEnter,
        onBlur: onMouseLeave,
    }

    return (
        <Popover
            id={componentId}
            open={isOpen}
            onOpenChange={setIsOpen}
            middleware={[
                flip({
                    fallbackPlacements: contentFallbackPlacements,
                }),
                shift({ limiter: limitShift() }),
                arrow({ element: arrowRef }),
                offset({ mainAxis: useOffset() }),
            ]}
            placement="top"
            disableBackdrop
            disablePortal={disablePortal}
            autoFocus={autoFocus}
        >
            <PopoverTrigger disabled={disabled}>
                {(triggerProps) =>
                    trigger ? (
                        trigger({ ...triggerProps, ...infoTipTriggerProps })
                    ) : (
                        <InfoTipTrigger label={label} {...triggerProps} {...infoTipTriggerProps} />
                    )
                }
            </PopoverTrigger>
            <PopoverContent zIndex={backdropZIndex}>
                {({ arrowX = 0, arrowY = 0, placement }) => (
                    <InfoTipBox>
                        <InfoTipArrow ref={arrowRef} placement={placement} left={px(arrowX)} top={px(arrowY)} />
                        {children}
                    </InfoTipBox>
                )}
            </PopoverContent>
        </Popover>
    )
}
