import * as React from 'react'

export const useControlled = <T>(
    controlledProp: T | undefined,
    defaultState: T,
    componentName: string,
): [T, (state: T) => void] => {
    const { current: controlled } = React.useRef(controlledProp !== undefined)
    const [state, setState] = React.useState(defaultState)

    const outputState = controlled ? (controlledProp ?? defaultState) : state
    const setStateIfUncontrolled = React.useCallback(
        (value: T) => {
            if (!controlled) {
                setState(value)
            }
        },
        [controlled, setState],
    )

    /*
     * Log an error to the console when the component
     * changes from uncontrolled to controlled or vice versa.
     */
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line react-compiler/react-compiler
        // eslint-disable-next-line react-hooks/rules-of-hooks, react-compiler/react-compiler
        React.useEffect(() => {
            if (controlled !== (controlledProp !== undefined)) {
                // eslint-disable-next-line no-console
                console.error(
                    `${componentName} changed from ${controlled ? 'controlled' : 'uncontrolled'} to ${
                        controlled ? 'uncontrolled' : 'controlled'
                    } version. It should not switch between those after initialization.`,
                )
            }
        }, [controlledProp, controlled, componentName])
    }

    return [outputState, setStateIfUncontrolled]
}
