import * as React from 'react'

import { IconButtonMinimalCore } from './icon-button-minimal-core'
import type { IconButtonProps } from './types'

export const IconButtonMinimal = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => (
    <IconButtonMinimalCore ref={ref} {...props} />
))

IconButtonMinimal.displayName = 'IconButtonMinimal'
