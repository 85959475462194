import * as React from 'react'

import type { Props as ButtonBaseProps } from './button-base'
import { ButtonBase } from './button-base'
import { getFocusRing, useFocusStyle } from '../../utils/focus'

const getTextColor = (variant: ButtonBaseProps['variant']) => {
    if (!variant || variant === 'brand') {
        return 'text'
    }

    if (variant === 'danger') {
        return 'dangerActive'
    }

    return variant
}

type Props = Omit<ButtonBaseProps, 'color' | 'backgroundColor'>

export const ButtonMinimal = React.forwardRef<HTMLButtonElement, Props>(({ variant = 'brand', css, ...props }, ref) => (
    <ButtonBase
        ref={ref}
        backgroundColor="transparent"
        borderColor="transparent"
        color={getTextColor(variant)}
        variant={variant}
        isButtonWithLabel
        css={[
            {
                '&:hover': {
                    ':not([aria-disabled="true"])': {
                        backgroundColor:
                            variant === 'danger' ? `${variant}Secondary` : `${variant}SecondaryHighlighted`,
                        color: getTextColor(variant),
                    },
                },
                '&:focus': {
                    color: getTextColor(variant),
                },
                ...getFocusRing(useFocusStyle(variant)),
                '&:active': {
                    ':not([aria-disabled="true"])': {
                        backgroundColor:
                            variant === 'danger' ? `${variant}SecondaryHighlighted` : `${variant}SecondaryActive`,
                        color: getTextColor(variant),
                    },
                },
                '&[aria-disabled="true"]': props.isLoading
                    ? /**
                       * Here an empty object needs to be passed, otherwise, the styling from the
                       * `ButtonBase` component won't be inherited.
                       */
                      {}
                    : {
                          backgroundColor: 'transparent',
                          color: 'backgroundEmphasis',
                      },
            },
            css,
        ]}
        {...props}
    />
))

ButtonMinimal.displayName = 'ButtonMinimal'
