import * as React from 'react'

import { isNil } from 'remeda'

import { checkboxSizeMap, labelPaddingYMap, labelSizeMap } from './constants'
import type { Ref, Size } from '../../types'
import { useFocusStyle } from '../../utils/focus'
import { useComposeRefs } from '../../utils/use-compose-refs'
import { useControlled } from '../../utils/use-controlled'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'
import { useFieldContext } from '../field/context'
import { IconCheckBox } from '../icons/generated/check-box'
import { IconMinus } from '../icons/generated/minus'
import { useWrappedIconSizing } from '../icons/use-wrapped-icon-sizing'
import type { InfoTipProp } from '../info-tip/utils'
import { getInfoTipByProps, useInfoTipWrapperId } from '../info-tip/utils'
import { Stack } from '../stack/stack'

type InputRef = ((instance: HTMLInputElement | null) => void) | React.MutableRefObject<HTMLInputElement | null> | null

type OnChange = (event: React.ChangeEvent<HTMLInputElement>) => void

type InvisibleInputProps<TValue extends string> = {
    inputRef?: InputRef
    indeterminate?: boolean
    css?: BoxProps['css']
    size?: Size
    type?: string
    onChange?: OnChange
    checked?: boolean
    disabled?: boolean
    name?: string
    value?: TValue
    id?: string
    required?: boolean
    focusable?: boolean
    autoFocus?: boolean
}

type ConditionalWrapperProps<TValue extends string> = Pick<Props<TValue>, 'disabled' | 'infoTip' | 'label' | 'id'> & {
    size: Size
    children: JSX.Element
}

/**
 * Necessary wrapper for the `Checkbox` component to make it backward compatible after adding the `label` and `infoTip` props.
 * Will render different HTML structure when label should be visible.
 */
const ConditionalWrapper = <TValue extends string>({
    children,
    label,
    infoTip,
    id,
    size,
    disabled,
}: ConditionalWrapperProps<TValue>) => {
    if (isNil(label) && isNil(infoTip)) {
        // Just return children without any changes in HTML.
        return children
    }

    const infoTipAlignmentProps = { alignItems: infoTip ? 'center' : undefined } as const

    return (
        <Stack orientation="horizontal" spacing={size === 'x-large' ? 'small' : 'x-small'} {...infoTipAlignmentProps}>
            {children}
            <Stack
                orientation="horizontal"
                spacing={size === 'x-large' ? 'x-small' : 'xx-small'}
                {...infoTipAlignmentProps}
            >
                {label && (
                    <Box
                        as="label"
                        htmlFor={id}
                        paddingY={labelPaddingYMap[size]}
                        css={{
                            cursor: 'pointer',
                            fontSize: labelSizeMap[size],
                            lineHeight: labelSizeMap[size],
                        }}
                        color={disabled ? 'textMuted' : undefined}
                    >
                        {label}
                    </Box>
                )}
                {infoTip && getInfoTipByProps({ props: infoTip, isWrapperComponentDisabled: disabled })}
            </Stack>
        </Stack>
    )
}

const InvisibleInput = <TValue extends string>({
    inputRef,
    indeterminate,
    css,
    size = 'medium',
    focusable = true,
    disabled,
    onChange,
    autoFocus,
    value,
    ...props
}: InvisibleInputProps<TValue>) => {
    const setRef = React.useCallback(
        (mutableInput: HTMLInputElement | null) => {
            if (mutableInput && inputRef) {
                if (typeof inputRef === 'function') {
                    inputRef(mutableInput)
                } else {
                    // eslint-disable-next-line react-compiler/react-compiler
                    inputRef.current = mutableInput
                }

                mutableInput.indeterminate = !!indeterminate
            }
        },
        [indeterminate, inputRef],
    )

    return (
        <Box
            as="input"
            width={size}
            height={size}
            css={[
                {
                    position: 'absolute',
                    opacity: 0,
                    zIndex: 1,
                    cursor: 'inherit',
                },
                css,
            ]}
            ref={setRef}
            autoFocus={focusable && autoFocus}
            {...(!focusable && { tabIndex: -1 })}
            {...(disabled
                ? {
                      disabled,
                      'aria-disabled': 'true',
                      defaultValue: value,
                  }
                : { onChange, value })}
            {...props}
        />
    )
}

type Props<TValue extends string | null> = {
    inputRef?: Ref<HTMLInputElement>
    checked?: boolean
    defaultChecked?: boolean
    onChange?: OnChange
    size?: Size
    disabled?: boolean
    indeterminate?: boolean
    name?: string
    value?: TValue
    label?: string
    id?: string
    css?: BoxProps['css']
    required?: boolean
    focusable?: boolean
    infoTip?: InfoTipProp
    autoFocus?: boolean
}

const CheckboxComponent = <TValue extends string | null>(
    {
        inputRef,
        checked: checkedProp,
        defaultChecked = false,
        onChange,
        size = 'medium',
        indeterminate,
        name,
        label,
        infoTip,
        id,
        value,
        css,
        focusable = true,
        autoFocus,
        ...props
    }: Props<TValue>,
    ref: React.Ref<HTMLInputElement>,
) => {
    const field = useFieldContext()
    const { disabled = field.disabled ?? false, required = field.required, ...rest } = props
    const checkboxSize = checkboxSizeMap[size]
    const wrappedIconSizing = useWrappedIconSizing(checkboxSize)
    const elementId = useInfoTipWrapperId(id, label)

    const [checked, setUncontrolledChecked] = useControlled(checkedProp, defaultChecked, 'Checkbox')

    const handleChange = React.useCallback<OnChange>(
        (event) => {
            if (onChange) {
                onChange(event)
            }

            /*
             * Do not allow to change Checkbox internal
             * state (uncontrolled) when indeterminate
             * prop is provided.
             */
            if (indeterminate === undefined) {
                setUncontrolledChecked(!checked)
            }
        },
        [onChange, setUncontrolledChecked, indeterminate, checked],
    )

    const focusStyles = useFocusStyle('brand', true)
    const filled = checked || indeterminate
    const innerInputRef: InputRef = React.useRef<HTMLInputElement>(null)
    const composedInputRef = useComposeRefs(innerInputRef, inputRef)
    const necessaryStylingForCheckboxWithLabel = isNil(label)
        ? {}
        : ({
              marginY: ['x-small', 'small'].includes(checkboxSize) ? 'xx-small' : 'x-small',
          } as const)

    return (
        <ConditionalWrapper id={elementId} label={label} infoTip={infoTip} size={size} disabled={disabled}>
            <Box
                ref={ref}
                as="span"
                borderStyle="solid"
                backgroundColor={filled ? (disabled ? 'border' : 'brand') : 'background'}
                borderRadius="medium"
                borderColor={disabled ? 'border' : filled ? 'brand' : 'text'}
                borderWidth="thick"
                width={checkboxSize}
                height={checkboxSize}
                {...necessaryStylingForCheckboxWithLabel}
                css={[
                    {
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        flexShrink: 0,
                        ...(!disabled && {
                            '&:hover': {
                                borderColor: 'brand',
                                ...(filled && {
                                    backgroundColor: 'brandHighlighted',
                                    borderColor: 'brandHighlighted',
                                }),
                            },
                        }),

                        cursor: disabled ? 'not-allowed' : 'pointer',
                    },
                    css,
                ]}
                {...rest}
            >
                {indeterminate ? (
                    <IconMinus color="background" css={wrappedIconSizing} />
                ) : checked ? (
                    <IconCheckBox color="background" css={wrappedIconSizing} />
                ) : null}

                <InvisibleInput
                    type="checkbox"
                    onChange={handleChange}
                    checked={checked}
                    disabled={disabled}
                    focusable={focusable}
                    inputRef={composedInputRef}
                    indeterminate={indeterminate}
                    name={name}
                    id={elementId}
                    size={checkboxSize}
                    css={{
                        '&.focus-visible + span': focusStyles,
                        '&:focus-visible + span': focusStyles,
                        '&[data-focus-visible-added] + span': focusStyles,
                    }}
                    {...(value ? { value } : {})}
                    aria-checked={indeterminate ? 'mixed' : checked === true}
                    required={required}
                    autoFocus={focusable && autoFocus}
                />

                {/*
                 * The following Box is used for displaying
                 * focus-visible styles only. It's needed
                 * because the InvisibleInput has to be a
                 * child of another Box container to keep
                 * its hover events working correctly.
                 */}
                <Box
                    as="span"
                    css={{
                        position: 'absolute',
                        borderRadius: 1,
                    }}
                />
            </Box>
        </ConditionalWrapper>
    )
}

export const Checkbox = React.forwardRef(CheckboxComponent) as (<TValue extends string | null>(
    props: Props<TValue>,
) => React.ReactElement) & { displayName: string }

Checkbox.displayName = 'Checkbox'
