import * as React from 'react'

import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

type Props = Omit<BoxProps, 'display' | 'flexDirection'> & {
    direction?: 'row' | 'column'
    priority?: 'start' | 'end' | 'both'
}

const prioritySelectorMap = {
    start: '& > :first-child',
    end: '& > :last-child',
    both: '& > *',
}

export const StartEnd = React.forwardRef<HTMLElement, Props>(({ direction = 'row', priority, ...props }, ref) => (
    // https://github.com/pmedianetwork/design-system/issues/857
    <Box
        ref={ref}
        display="flex"
        flexDirection={direction}
        justifyContent="space-between"
        alignItems="stretch"
        css={
            priority
                ? {
                      [prioritySelectorMap[priority]]: { flexGrow: 1 },
                  }
                : {}
        }
        {...props}
    />
))

StartEnd.displayName = 'StartEnd'
