import * as React from 'react'

import type { UseFloatingReturn, useInteractions } from '@floating-ui/react'
import { useMergeRefs } from '@floating-ui/react'

import { Box } from '../box/box'

type Props = {
    children: React.ReactElement<React.HTMLProps<Element>> & { ref?: React.Ref<HTMLElement> }
    id: string
    setIsOpen: (visible: boolean) => void
    setReference: UseFloatingReturn['refs']['setReference'] // (node: ReferenceType | null) => void
    getReferenceProps: ReturnType<typeof useInteractions>['getReferenceProps']
}

export const TooltipInternalTrigger = ({ children, setIsOpen, setReference, id, getReferenceProps }: Props) => {
    const ref = useMergeRefs(children.ref ? [setReference, children.ref] : [setReference])

    const triggerElement = React.cloneElement(
        children,
        getReferenceProps({
            ref,
            'aria-describedby': id,
            ...children.props,
            onKeyDown: (event) => {
                children.props.onKeyDown?.(event)

                if (event.key === 'Escape') {
                    setIsOpen(false)
                    event.stopPropagation()
                }
            },
        }),
    )

    return children.props.disabled ? (
        <Box display="inline-block" ref={ref} onBlur={() => setIsOpen(false)} onFocus={() => setIsOpen(true)}>
            {triggerElement}
        </Box>
    ) : (
        triggerElement
    )
}
