/* eslint id-length: ["error", { "exceptions": ["x", "y"] }] */

import * as React from 'react'

import { useMergeRefs } from '@floating-ui/react'

import { PopoverContext } from './popover'
import type { PopoverContentProps } from './types'
import { getFocusRing, useFocusStyle } from '../../utils/focus'
import { backdropZIndex } from '../backdrop/backdrop'
import { DialogBackdrop } from '../dialog/dialog-backdrop'
import { DialogContainer } from '../dialog/dialog-container'
import { DialogInternal } from '../dialog/dialog-internal'

export const PopoverContent = React.forwardRef<HTMLElement, PopoverContentProps>(
    ({ children, zIndex = backdropZIndex, css, ...props }, propsRef) => {
        const popoverContext = React.useContext(PopoverContext)

        if (!popoverContext) {
            throw new Error('Error, state not initialised. Did you use PopoverContent outside the Popover component?')
        }

        const {
            id,
            isOpen,
            setIsOpen,
            floating,
            x,
            y,
            strategy,
            getFloatingProps,
            disablePortal,
            disableBackdrop = false,
            autoFocus = false,
            contain = false,
            shouldCloseOnBlur = true,
            restoreFocus = true,
            middlewareData,
            refs,
        } = popoverContext

        const focusStyles = getFocusRing(useFocusStyle())

        const ref = useMergeRefs([floating, propsRef])

        const renderProps = {
            ...popoverContext,
            arrowX: middlewareData.arrow?.x,
            arrowY: middlewareData.arrow?.y,
        }

        const popoverContent = (
            <DialogInternal
                id={id}
                ref={ref}
                borderRadius="medium"
                autoFocus={autoFocus}
                contain={contain}
                restoreFocus={restoreFocus}
                zIndex={zIndex}
                position={strategy}
                top={y}
                left={x}
                css={[focusStyles, css, { pointerEvents: 'auto' }]}
                shouldCloseOnBlur={false}
                onBlur={(event) => {
                    if (
                        shouldCloseOnBlur &&
                        !refs.floating.current?.contains(event.relatedTarget) &&
                        refs.reference.current !== event.relatedTarget
                    ) {
                        // Implement close on blur because Dialog's shouldCloseOnBlur is incompatible with floating-ui
                        setIsOpen(false)
                    }
                }}
                {...getFloatingProps()}
                {...props}
            >
                {typeof children === 'function' ? children(renderProps) : children}
            </DialogInternal>
        )

        return isOpen ? (
            <DialogContainer disablePortal={disablePortal}>
                {disableBackdrop ? (
                    popoverContent
                ) : (
                    <DialogBackdrop backgroundColor="transparent" css={{ pointerEvents: 'none' }}>
                        {popoverContent}
                    </DialogBackdrop>
                )}
            </DialogContainer>
        ) : null
    },
)

PopoverContent.displayName = 'PopoverContent'
