import * as React from 'react'
import { IconProps } from '../shared'
import { Icon } from '../icon'
export const IconCheck = React.forwardRef<HTMLElement, IconProps>((props, ref) => (
    <Icon ref={ref} {...props}>
        <path
            fill="#111928"
            fillRule="evenodd"
            d="M18.556 8.657l-1.414-1.414-7.485 7.485-3.243-3.243L5 12.9l3.243 3.242 1.414 1.414 8.9-8.9z"
            clipRule="evenodd"
        />
    </Icon>
))
IconCheck.displayName = 'IconCheck'
